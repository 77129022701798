import './App.css';
import Navbar from './components/Navbar';
import Landing from "./components/Landing"
import About from './components/About';
import SponsorMarquee from './components/SponsorMarquee';
import Details from './components/Details';
import Contact from './components/Contact';
import Collages from './components/Collages';
import Event from './components/Event';


function App() {
  return (
    <div className="App">
      <Navbar />
      <Landing />
      <div className=' pt-[200px] pb-[50px] w-full bg-[#111] flex justify-center items-center'>
        <img className=' w-[80%] sm:w-[60%] lg:w-[40%]' src={require("./assets/images/events/college/5.png")} alt='Ganpat University' />
      </div>
      <Collages />
      <About />
      {/* <Events /> */}
      <Event />
      <SponsorMarquee />
      <Details />
      <Contact />
    </div>
  );
}

export default App;
