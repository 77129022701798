const collageData = [
    "college/1.png",
    "college/2.png",
    "college/3.png",
    "college/1.png",
    "college/2.png",
    "college/3.png",
    "college/1.png",
    "college/2.png",
    "college/3.png",
    
]

export default collageData;