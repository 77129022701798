const sponsorData = [
    "sponsor/1.jpg",
    "sponsor/2.png",
    "sponsor/3.jpeg",
    "sponsor/4.jpeg",
    "sponsor/5.png",
    "sponsor/6.png",
    "sponsor/7.png",
    "sponsor/8.jpg",
    "sponsor/9.jpg",
    "sponsor/10.jpg",
    "sponsor/11.jpg",
    "sponsor/12.png",
    "sponsor/1.jpg",
    "sponsor/2.png",
    "sponsor/3.jpeg",
    "sponsor/4.jpeg",
    "sponsor/5.png",
    "sponsor/6.png",
    "sponsor/7.png",
    "sponsor/8.jpg",
    "sponsor/9.jpg",
    "sponsor/10.jpg",
    "sponsor/11.jpg",
    "sponsor/12.png",
    "sponsor/1.jpg",
    "sponsor/2.png",
    "sponsor/3.jpeg",
    "sponsor/4.jpeg",
    "sponsor/5.png",
    "sponsor/6.png",
    "sponsor/7.png",
    "sponsor/8.jpg",
    "sponsor/9.jpg",
    "sponsor/10.jpg",
    "sponsor/11.jpg",
    "sponsor/12.png",
];

export default sponsorData;