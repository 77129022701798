
import React, { useState } from 'react'
function HomeFirstPopUp() {
    const [View, setView] = useState(false)
    const viewfunc = () => {
        setTimeout(() => {
            setView((val) => { return !val })
        }, 1000);
    }


    return (
        <div  >
            <button className='hidden' onClick={() => { setView((val) => { return !val }) }}>Modal</button>

            <div
                onLoad={() => viewfunc()}
                className={`absolute bg-white flex flex-col items-center rounded-lg
             top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20
              overflow-hidden shadow-lg w-full max-w-2xl author-codingarpan(www.codingarpan.tech) 
              h-fit max-h-md md:max-h-screen md:mt-10 transition-all duration-700 ease-in-out ${View ? 'top-1/2 opactity-100' : '-top-0 opacity-0'} `}>
                <button className='absolute top-0 z-10 right-0 bg-rose-500 text-white px-4 py-2 rounded'
                    onClick={() => { setView((val) => { return !val }) }}>Close</button>
                <div className="body text-black text-sm rounded-lg">
                    <img className='w-fit h-max object-cover object-center p-2' src={require('../assets/images/popup_h.png')} alt="demo" />
                </div>
            </div>


        </div>)
}
export default HomeFirstPopUp

